<template>
  <div class="">
    <form @submit.prevent="loginUser"  v-if="tab === 'login'" :ref="form">
      <div class="flex text-orange-700 relative mb-2" v-show="error">
        <div class="flex h-3 w-3 relative">
          <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
          <span class="relative inline-flex rounded-full h-3 w-3 bg-orange-500"></span>
        </div>
        <div>{{error}}</div>
      </div>
      <InputOverlappingLabel required="required" class="mb-2" label="Adresse e-mail" v-model="email" name="email" autocomplete="email"  placeholder="utilisateur@domaine.extension" type="email"></InputOverlappingLabel>
      <InputOverlappingLabel required="required" class="my-5" label="Mot de passe" v-model="password" name="password" autocomplete="current-password"  placeholder="password" type="password"></InputOverlappingLabel>
      <div class="flex items-center">
        <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
        <label for="remember-me" class="ml-2 block text-sm text-gray-900">
          Se souvenir de moi
        </label>
      </div>
      <div class="relative">
        <button type="submit"
          :disabled="loading"
          class="relative w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Connexion
        </button>
        <HoverSpinner color="blue" v-if="loading"/>
      </div>
      <p class="auth-text">
        <button type="button" class="link" @click.prevent="forgot()" :disabled="loading"> Mot de passe oublié ? </button>
      </p>
    </form>
  </div>
</template>
<script setup>
import InputOverlappingLabel from "~/components/forms/InputOverlappingLabel.vue";
import {onMounted, ref, toRef, watch} from "vue";
import Spinner from "~/components/Spinner.vue";
import {useMessage} from '~/src/functions/useMessages.js';
import HoverSpinner from "~/components/layout/HoverSpinner.vue";
import {useStrapiUserStore} from '~/stores/strapi-user';
import {getErrorMessageFromAxiosResponse} from "~/src/datas/strapi-api-messages";
import {isLocalEnv} from "~/src/functions/isLocalDev.js";

const userStore = useStrapiUserStore();
const email = ref('');
const password = ref('');
const loading = ref(false);
const props = defineProps({
  defaultTab: {type: String, default: () => 'login'}
})
const emit = defineEmits(['success']);
const form = ref();

if (isLocalEnv()) {
  email.value = 'poinot.thierry@gmail.com';
  password.value = 'poinot.thierry@gmail.com';
}

const {message: error, showAfter: showErrorMessageAfter, show: showErrorMessage} = useMessage();
const loginUser = async () => {
  console.log('loginUser', {identifier: email.value, password: password.value})
  loading.value = true;
  const requestTimeout = setTimeout(() => {
    loading.value = false;
    showErrorMessageAfter('Le serveur à mis trop de temps à répondre.', 0, 5);
  }, 5000)
  try {
    await userStore.login({identifier: email.value, password: password.value});
    emit('success');
  }
  catch (err) {
    showErrorMessage(getErrorMessageFromAxiosResponse(err), 5);
  }
  finally {
    clearTimeout(requestTimeout);
    loading.value = false;
  }
}


const forgot = async () => {
  loading.value = true;
  if (!email.value) {
    showErrorMessage('Votre e-mail est requis');
    // form.value.querySelector('input[name=email]').setCustomValidity('Votre e-mail est requis');
    // form.value.querySelector('input[name=email]').reportValidity();
    return;
  }
  try {
    await userStore.forgot({email: email.value});
    showErrorMessage(`Un email vient de vous être envoyé à l'adresse ${email.value}`);
  }
  catch (e) {
    showErrorMessage(getErrorMessageFromAxiosResponse(e), 5);
  }
  finally {
    loading.value = false;
  }
};
const defaultTab = toRef(props, 'defaultTab')
const tab = ref(defaultTab.value+'')
watch(defaultTab, (t) => tab.value = t);

</script>
